import React from "react";

import Title from './TitleForScavengerEvents'
import Events from './ScavengerEventLayout'
import Connect from './ConnectEvent'
import 'bootstrap/dist/css/bootstrap.min.css';

const MainPage = () => {
  window.scrollTo(0,0);
  return(
    <div>
      <Title/>
      <Events/>
      <Connect/>
    </div>
  );
};

export default MainPage;
