import firebase from 'firebase/app';
import 'firebase/database'

var firebaseConfig = {
  apiKey: "AIzaSyAdnug47s4HTG8RUt20wytn8MPYxe1Nn3k",
  authDomain: "we-are-hoboken-a62f2.firebaseapp.com",
  databaseURL: "https://we-are-hoboken-a62f2.firebaseio.com",
  projectId: "we-are-hoboken-a62f2",
  storageBucket: "we-are-hoboken-a62f2.appspot.com",
  messagingSenderId: "533206202136",
  appId: "1:533206202136:web:0c6fe0e44493e1a73e106d"
}
var db = firebase.initializeApp(firebaseConfig);
export default db;
