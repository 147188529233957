import React from 'react';
// import ReactDOM from 'react-dom';
import './CSS/Main.css';
import * as ReactBootstrap from 'react-bootstrap';

class Navbar extends React.Component {
  render() {
    return(
      <ReactBootstrap.Navbar className="nav-bar" collapseOnSelect expand="lg" variant="light" sticky="top">
        <ReactBootstrap.Navbar.Brand href="#">Hoboken Unites Here</ReactBootstrap.Navbar.Brand>
        <ReactBootstrap.Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <ReactBootstrap.Navbar.Collapse id="responsive-navbar-nav">
        <ReactBootstrap.Nav className="mr-auto">

</ReactBootstrap.Nav>
            <ReactBootstrap.Nav >
              <li>
                <a className="nav-link scroll" href="#mission">OUR COMMITMENT</a>
              </li>
              <li>
                <a className="nav-link scroll" href="#events">EVENTS</a>
              </li>
              <li>
                <a className="nav-link scroll" href="#howToHelp">GET INVOLVED</a>
              </li>
              <li>
                <a className="nav-link scroll" href="#team">THE TEAM</a>
              </li>
              <li>
                <a className="nav-link scroll" href="#contact">CONNECT</a>
              </li>
            </ReactBootstrap.Nav>
        </ReactBootstrap.Navbar.Collapse>
      </ReactBootstrap.Navbar>
    );
}
}

export default Navbar;
