import React from 'react';
// import ReactDOM from 'react-dom';
import '/home/jonathan/Documents/All Hobokn things/wahr to deploy/src/Pages/Components/CSS/Main.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope} from '@fortawesome/free-solid-svg-icons';
import { faInstagram, faReddit } from '@fortawesome/free-brands-svg-icons';
import {db} from '../../firebase';
import firebase from 'firebase';

const Connect = () => {

  var MessagesRef = firebase.database().ref('Banner');

  function submitForm(e){
  	e.preventDefault();

  	//get values
  	var name = getInputVal('name');
  	var email = getInputVal('email');
    var subject = getInputVal('subject');
  	var message = getInputVal('message');

  	saveMessage(name, email, subject, message);

  	//Show Alert
  	alert("Your message has been sent");

  }

  //function to get form values
  function getInputVal(id){
  	return document.getElementById(id).value;
  }

  //function to save message to firebase
  function saveMessage(name, email, subject, message){
  	var newMessageRef = MessagesRef.push();
  	newMessageRef.set({
  		name: name,
  		email: email,
      subject: subject,
  		message: message
  	});
  }

    return (
      <section id="contact">
        <div className="container">
          <h1>Get In Touch</h1>
          <p>Please fill in your name, email, subject or event you are contacting about, and what you want to tell us. Thank you for reaching out to us!</p>
          <div className="row">
            <div className="col-md-6">
              <form className="contact-form" onSubmit={submitForm}>
                <div className="form-group">
                  <input
                  type="text"
                  className="form-control"
                  placeholder="Name"
                  name="name"
                  id="name"
                  />
                </div>
                <div className="form-group">
                  <input
                  type="email"
                  className="form-control"
                  placeholder="Email"
                  name="email"
                  id="email"
                  />
                </div>
                <div className="form-group">
                  <input
                  type="text"
                  className="form-control"
                  placeholder="Subject"
                  name="subject"
                  id="subject"
                  />
                </div>
                <div className="form-group">
                  <textarea
                  type="text"
                  className="form-control"
                  rows={5}
                  placeholder="Message"
                  name="message"
                  id="message"
                  />
                </div>
                <button type="submit" className="btn btn-primary">SEND</button>
              </form>
            </div>
            <div className="col-md-6 contact-info">
              <div className="follow"><b>Email </b><i className="fas fa-envelope"><FontAwesomeIcon icon={faEnvelope}/></i> :  WeAreHoboken@gmail.com</div>
              <div className="follow"><b>Instagram </b><i className="fab fa-instagram"><FontAwesomeIcon icon={faInstagram}/></i> : WeAreHoboken</div>
              {/*                        <div class="follow"><b>Facebook </b><i class="fab fa-facebook"></i> : WeAreHoboken@gmail.com</div>*/}
              <div className="follow"><b>Reddit </b><i className="fab fa-reddit"><FontAwesomeIcon icon={faReddit}/></i> : WeAreHoboken</div>
            </div>
          </div>
        </div>
      </section>
);
}

export default Connect;
