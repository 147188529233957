import React from 'react';
// import ReactDOM from 'react-dom';
import './CSS/Main.css';
import * as ReactBootstrap from 'react-bootstrap';

class Navbar extends React.Component {
  render() {
    return(
      <ReactBootstrap.Navbar className="nav-bar" collapseOnSelect expand="lg" bg="light" variant="light" sticky="top">
        <ReactBootstrap.Navbar.Brand href="/">Home</ReactBootstrap.Navbar.Brand>
      </ReactBootstrap.Navbar>
    );
}
}

export default Navbar;
