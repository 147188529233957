import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';

const NotFoundPage = () => {
  return(
    <div>
      <h1>404 Page Not Found </h1>
    </div>
  );
};

export default NotFoundPage;
