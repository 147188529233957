import React from 'react';
//import ReactDOM from 'react-dom';
import './CSS/Main.css';
import Image1 from './Images/Hoboken4.jpg';
import Image2 from './Images/Hoboken5.jpg';
import Image3 from './Images/Hoboken6.jpg';
import {Link} from "react-router-dom";

class Events extends React.Component {
  render() {
    return (
      <section id="events" className="events">
        <h1>Events</h1>
        <div className="container">
          <div className="row">
            <div className="col-md-4">
              <div className="event-box">
                <Link to="/BannerEvent">
                  <img src={Image1} alt=""/>
                  <h4><b>Come Meet Us!</b></h4>
                  <h6>11/21/2020</h6>
                  <p>Come to the pier and meet us! Sign the banner and we will hang it up in Hoboken</p>
                </Link>
              </div>
            </div>
            <div className="col-md-4">
              <div className="event-box">
                <Link to="/Trivia">
                  <img src={Image2} alt=""/>
                  <h4>Trivia</h4>
                  <h6>11/28/2020</h6>
                  <p>Join us with a team of 6 for a fun Zoom trivia game </p>
                </Link>
              </div>
            </div>
            <div className="col-md-4">
              <div className="event-box">
                <Link to="/ScavengerHunt">
                  <img src={Image3} alt=""/>
                  <h4>Scavenger Hunt</h4>
                  <h6>12/5/2020</h6>
                  <p>Join us for a fun scavenger hunt around Hoboken </p>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
);
}
}

export default Events;
