import React from "react";
import Navbar from './Components/Navbar'
import Title from './Components/Title'
import Commitment from './Components/Commitment'
import Events from './Components/Events'
import Team from './Components/Team'
import Connect from './Components/Connect'
import GetInvolved from './Components/GetInvolved'
import 'bootstrap/dist/css/bootstrap.min.css';

const MainPage = () => {
  return(
    <div>
      <Navbar/>
      <Title/>
      <Commitment/>
      <Events/>
      <GetInvolved/>
      <Team/>
      <Connect/>
    </div>
  );
};

export default MainPage;
