import React from 'react';
// import ReactDOM from 'react-dom';
import './CSS/Main.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLightbulb, faShareAlt, faCube } from '@fortawesome/free-solid-svg-icons'
import {Link} from "react-router-dom";

class Commitment extends React.Component {
  render() {
    return (
      <section id="mission">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <h1>Our Commitment</h1>
              <div className="mission-content">
                <p>Everyone wants to see change for the community, but taking the first step can be really difficult and discouraging. We made it our mission to help you fulfill your desire to do good, and to share your love for your community.<br /> Our process has three easy steps that turns your vision to reality, keeping you involved as much as you would like.</p>
              </div>
              <a href="#contact"><button type="button" className="btn btn-primary scroll">Contact Us</button></a>
              <Link to="./../Donate"><button type="button" className="btn btn-primary scroll">Help Our Goal</button></Link>
            </div>
            <div className="col-md-6">
              <div className="process">
                <div className="feature-left">
                  <i className="fa fa-lightbulb"><FontAwesomeIcon icon={faLightbulb}/></i>
                </div>
                <div className="feature-right">
                  <h4>Brainstorm</h4>
                  <p>Together we will come up with a purposeful and impactful event</p>
                </div>
              </div>
              <div className="process">
                <div className="feature-left">
                  <i className="fa fa-share-alt"><FontAwesomeIcon icon={faShareAlt}/></i>
                </div>
                <div className="feature-right">
                  <h4>Network</h4>
                  <p>With connections, we can get the necessary people involved for a smooth process</p>
                </div>
              </div>
              <div className="process">
                <div className="feature-left">
                  <i className="fa fa-cube"><FontAwesomeIcon icon={faCube}/></i>
                </div>
                <div className="feature-right">
                  <h4>Build</h4>
                  <p>As a community we make your vision a reality</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
);
}
}

export default Commitment;
