import React from 'react';
// import ReactDOM from 'react-dom';
import './CSS/Main.css';
import Image from './Images/Hoboken3.jpg'
import {Link} from "react-router-dom";

class Title extends React.Component {
  render() {
    return (
      <div id="slider">
       <div id="headerSlider" className="carousel slide" data-interval={5000} data-ride="carousel">
         <div className="carousel-inner">
           <div className="carousel-item active">
             <img className="d-block img-fluid" src={Image} alt="First slide" />
             <div className="carousel-caption">
               <h5>Uniting Hoboken</h5>
               <h2>WE ARE</h2>
               <h2>HOBOKEN</h2>
               <a href="#events"><button className="btn btn-info">Events</button></a>
               <Link to="./../Donate"><button className="btn btn-info">Contribute</button></Link>
               <br/>
                <br/>
               <h5>One event at a time</h5>
             </div>
           </div>
         </div>
       </div>
     </div>
);
}
}

export default Title;
