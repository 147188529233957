import React from "react";
import Navbar from './Components/NavbarDonate'
import Connect from './Components/ConnectDonate'
import 'bootstrap/dist/css/bootstrap.min.css';

const MainPage = () => {
  return(
    <div>
      <Navbar/>
      <Connect/>
    </div>
  );
};

export default MainPage;
