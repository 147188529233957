import React, {Component} from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";
import MainPage from "./Pages"
import NotFoundPage from "./Pages/404"
import Donate from "./Pages/Donate"
import BannerEvent from "./Pages/NewEvents/BannerEvent/BannerEvent"
import TriviaEvent from "./Pages/NewEvents/Trivia/TriviaEvent"
import ScavengerEvent from "./Pages/NewEvents/ScavengerHunt/ScavengerEvent"



class App extends Component {
  render() {
    return (
    <Router>
      <Switch>
        <Route exact path="/" component={MainPage} />
        <Route exact path="/404" component={NotFoundPage} />
        <Route exact path="/Donate" component={Donate} />
        <Route exact path="/BannerEvent" component={BannerEvent} />
        <Route exact path="/Trivia" component={TriviaEvent} />
        <Route exact path="/ScavengerHunt" component={ScavengerEvent} />
        <Redirect to="/404" />
      </Switch>
    </Router>
    );
  }
}

export default App;
