import React from 'react';
//import ReactDOM from 'react-dom';
import '../../Components/CSS/Events.css';
import host1 from '../../Components/Images/00.jpeg'
import host2 from '../../Components/Images/DSC_0802.JPG'
import {Link} from "react-router-dom";

class Events extends React.Component {
  render() {
    return (
      <div>
        <section id="information">
          <div className="container">
            <div className="row">
              <div className="col-md-4">
                <h1>HOST</h1>
                <div className="img-box">
                  <img src={host1} className="Jon" />
                  <h2>Jonathan Amir</h2>
                </div>
                <div className="img-box">
                  <img src={host2} className="Jon" />
                  <h2>Amanda Schmitt</h2>
                </div>
                <p className="bio">We are the founders of We Are Hoboken and we are really excited to meet everyone</p>
                <br />
                {/*                            <h5>Contact info:</h5>*/}
                <p className="contact"><b>Phone:</b> 718-490-9927</p>
                <p className="contact"><b /> Email: WeAreHoboken@gmail.com</p>
                {/*                            <p class="contact">Other</p>*/}
              </div>
              <div className="col-md-8">
                <div>
                  <h1 className="about">About the Event:</h1>
                  <p>Join us for a fun game of Zoom trivia! Invite your friends to form a team of up to 6 people and compete against Hobokens smartest minds.
                  The winning team will get a prize and a shoutout on our Instagram Page</p>
                </div>
                <br />
                <div>
                  <h2 className="DTL">Date/Time/Location</h2>
                  <p>Saturday November 28th<br />5:00pm - 6:00pm<br />On Zoom: <a href="">Link to be made</a></p>
                </div>
                <br />
                <div>
                  <h2 className="details"> What to expect:</h2>
                  <ul>
                    <li>5 rounds</li>
                    <li>Prizes for the winning team</li>
                    {/*                                <li>Prizes for the winning team</li>*/}
                  </ul>
                </div>
                {/*                    <br>  */}
                <br />
                <h3>REGISTER BELOW</h3>
              </div>
            </div>
          </div>
        </section>
      </div>
);
}
}

export default Events;
