import React from 'react';
//import ReactDOM from 'react-dom';
import './CSS/Main.css';
import jonImage from './Images/0.jpeg'
import amanImage from './Images/SmartSelect_20200214-111451_Photos.png'

class Team extends React.Component {
  render() {
    return (
      <section id="team">
        <div className="container">
          <h1>Meet the Team</h1>
          <div className="row">
            <div className="col-md-6 profile-pic text-center">
              <div className="img-box">
                <img src={jonImage} className="img-responsive" alt=" "/>
              </div>
              <h2>Jonathan Amir</h2>
              <h3>Co-Founder</h3>
              <p>I helped start this company with the hope that people can work in unison to improve their community. I want to take the difficulty out of community service, and allow anyone who wants to help be able to do so worry free.</p>
            </div>
            <div className="col-md-6 profile-pic text-center">
              <div className="img-box">
                <img src={amanImage} className="img-responsive" alt=" " style={{height: '200px', width: '200px'}} />
              </div>
              <h2>Amanda Schmitt</h2>
              <h3>Co-Founder</h3>
              <p>Nothing is as rewarding to me as the feeling of community. We as a collective can accomplish incredible things. I want to bring the change that the collective aspires to see, one step at a time.</p>
            </div>
          </div>
        </div>
      </section>
);
}
}

export default Team;
