import React from 'react';
// import ReactDOM from 'react-dom';
import './CSS/Main.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUserCircle, faPeopleCarry, faHandHoldingMedical} from '@fortawesome/free-solid-svg-icons'
import {Link} from "react-router-dom";

class GetInvolved extends React.Component {
  render() {
    return (
      <section id="howToHelp">
        <div className="container">
          <h1>What You can Do</h1>
          <div className="row services">
            <div className="col-md-4 text-center">
              <div className="icon">
                <i className="fa fa-user-circle"><FontAwesomeIcon icon={faUserCircle}/></i>
              </div>
              <h3>Host</h3>
              <p>Be a part of the logisitical team for any of our events</p>
            </div>
            <div className="col-md-4 text-center">
              <div className="icon">
                <i className="fas fa-people-carry"><FontAwesomeIcon icon={faPeopleCarry}/></i>
              </div>
              <h3>Participate</h3>
              <p>Join us in one of our events and help make it possible</p>
            </div>
            <div className="col-md-4 text-center">
              <div className="icon">
                <i className="fas fa-hand-holding-medical"><FontAwesomeIcon icon={faHandHoldingMedical}/></i>
              </div>
              <h3>Contribute</h3>
              <p>Share your ideas with us, or help fund one of our events</p>
            </div>
            <div className="buttons-for-help">
              <a href="#contact"><button type="button" className="btn btn-primary scroll">Contact Us</button></a>
              <Link to="./../Donate"><button className="btn btn-primary scroll">Contribute</button></Link>
            </div>
          </div>
        </div>
      </section>
);
}
}

export default GetInvolved;
