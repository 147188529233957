import React from 'react';
// import ReactDOM from 'react-dom';
import '../../Components/CSS/Main.css';
import Image3 from '../../Components/Images/mika-baumeister-Hi4Ak40cy3g-unsplash.jpg';
import {Link} from "react-router-dom";

class Title extends React.Component {
  render() {
    return (
      <div id="head">
        <div className="carousel-inner">
          <div className="carousel-item active">
            <img className="d-block img-fluid" src={Image3} alt="First slide" />
            <div className="carousel-caption">
              <h2>Scavenger Hunt!!!</h2>
              <a href="#information"><button className="btn btn-info">Learn More</button></a>
              <a href="/"><button className="btn btn-info">Home</button></a>
              <a href="/Donate"><button className="btn btn-info">Contribute</button></a>
              <a href="#contact"><button className="btn btn-info">Register</button></a>
              <br />
              <br />
              <br />
            </div>
          </div>
        </div>
      </div>
);
}
}

export default Title;
